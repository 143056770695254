<template>
<div class="pt-10">
        <b-field grouped>
            <b-input placeholder="Course Name" class="w-80" v-model="NewCourse"></b-input>
            <p class="control">
                <b-button class="button is-primary" icon-pack='far'
                    icon-left="plus" @click="addcourse">Add Course</b-button>
            </p>
        </b-field>
        
        <div class="mt-16 height overflow-y-scroll no-scrollbar">
            <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                <tr>
                    <th scope="col" class="pl-16 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Index
                    </th>
                    <th scope="col" class="pl-16 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Course
                    </th>
                    <th scope="col" class="relative px-6 py-3">
                    <span class="sr-only">Delete</span>
                    </th>
                </tr>
                </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr v-for="(course,index) in courses" :key="course.id">
                  <td class="px-6 py-4 whitespace-nowrap">
                  <div class="flex items-center">
                    <div class="ml-4">
                      <div class="text-sm font-medium text-gray-900">
                        {{ index + 1 }}
                      </div>
                    </div>
                  </div>
                </td>

                <td class="px-6 py-4 whitespace-nowrap">
                  <div class="flex items-center">
                    <div class="ml-4">
                      <div class="text-sm font-medium text-gray-900">
                        {{ course.name }}
                      </div>
                    </div>
                  </div>
                </td>

                <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                  <button href="#" class="text-red-600 hover:text-red-900" @click="deletecourse(course.id)" >
                     <i class="far fa-trash-alt pr-2" /> Delete</button>
                </td>
              </tr>
            </tbody>
            </table>
        </div>

    </div>
</template>

<script>
import {mapState} from 'vuex'
import * as fb from '../firebase'
    export default {
        name:'Courses',
        data() {
            return {
                NewCourse:''
            }
        },
        computed:{
          ...mapState(['courses'])
        },
        methods: {
          async addcourse(){
            if (this.NewCourse != '') {
              await fb.courseCollection.doc().set({
                  name: this.NewCourse,
              });
              
            }
            this.NewCourse=''
          },
          async deletecourse(id){
            await fb.courseCollection.doc(id).delete();
          }
        },
    }
</script>

<style scoped>

</style>